<template>
  <div id="air-conditionindex">
    <div class="header">
      <my-indextitle :title="title" :imgurl="imgurl"></my-indextitle>
      <my-navdar :list="list"></my-navdar>
    </div>
    <div class="main">
      <keep-alive :exclude="cancelCache">
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import indexTitle from "../../components/module/indexTitle";
import navdar from "../../components/Navdar/horizontal";
import { getdata } from "../../api/api";
import { getTenantList } from "../../api/door";

export default {
  data() {
    return {
      flag:"BSDSAC",//暂时写死
      cancelCache: ["terminaleditor", "outputEdit"],
      form: {
        region: 0,
        region1: 0,
      },
      title: "",
      imgurl: require("../../../public/images/moduleimg/bsdsac.png"),
      options: [],
      list: JSON.parse(sessionStorage.getItem("sitemenu")) || [],
    };
  },
  async created() {
    this.spinning = true;
    await getTenantList(this.$route.query.id)
      .then((res) => {
        let { data } = res;
        for (let i = data.length - 1; i > -1; i--) {
          this.options.push({ value: data[i].id, name: data[i].name });
        }
        console.log("res", this.options);
      })
      .catch((err) => {
        console.log("出错", err);
      });
  },
  mounted() {
    // 导航栏数据初始化
    const value = this.$store.getters.loading;
    const Code = this.$route.query.Code;
    if (!value) {
      this.$store.commit("menu/SITE_MENU", Code);
      this.list = this.$store.getters.siteMenu;
      this.changeCode();
    }
    let id = this.$route.query.id;
    getdata(`bivale/custom/site/${id}`)
      .then((res) => {
        let { data } = res;
        this.title = data.name;
      })
      .catch((err) => {
        console.log("出错", err);
      });
  },
  watch: {
    // 监听多语言切换，导航栏数据同步切换
    "$store.getters.loading": {
      handler: function (newValue, oldValue) {
        const value = this.$route.query.Code;
        if (!newValue) {
          this.$store.commit("menu/SITE_MENU", value);
          this.list = this.$store.getters.siteMenu;
          this.changeCode();
        }
      },
      deep: true,
    },
  },
  methods: {
    //改变菜单中code，加上设备标志让其唯一
    changeCode(){
      if(this.list!=null&&this.list.length>0){
        for(let i=0;i<this.list.length;i++){
          this.changeCodeChildren(this.list[i],this.flag);
        }
        console.log("changeCode", this.list);
      }
    },

    //递归函数，所有children处理
    changeCodeChildren(item,flag){
      if(item.code.indexOf(flag+"_")<0)
        item.code = flag+"_"+item.code;
      if(item.children!=null&&item.children.length>0){
        for(let i=0;i<item.children.length;i++){
          this.changeCodeChildren(item.children[i],flag);
        }
      }       
    }
  },
  components: {
    "my-indextitle": indexTitle,
    "my-navdar": navdar,
  },
};
</script>

<style scoped>
/* 设备跟标签 */
#air-conditionindex {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px #bdbcbc;
  background: #ffffff;
  width: calc(100% - 40px);
  height: calc(100% - 30px);
  overflow: hidden;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.header {
  height: 100px;
  padding: 0px 20px 0px 20px;
}
</style>