<template>
  <div id="indextitle">
    <div class="indextitle-left">
      <img :src="imgurl" alt />
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { default: "" },
    imgurl: { default: "" },
  },
};
</script>

<style scoped>
#indextitle {
  width: 100%;
  padding: 20px 0px 0px 0px;
  white-space: nowrap;
  overflow: hidden;
}
.indextitle-left {
  display: flex;
  width: 100%;
  font-size: 20px;
}
.indextitle-left img {
  width: 30px;
  margin-right: 15px;
}
</style>